import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

import PageComponentGroups from "../components/PageComponentGroups"

const Page = props => {
  const { components, seo } = props.data
  return (
    <Layout>
      <Seo
        title={seo.pageSeoData.swbThemeMetaTitle}
        description={seo.pageSeoData.swbThemeDescription}
        metaImg={seo.pageSeoData.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <PageComponentGroups components={components} />
    </Layout>
  )
}

export const pageTempQuery = graphql`
  query pageTempPage($id: String!) {
    seo: wpPage(id: { eq: $id }) {
      pageSeoData {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    components: wpPage(id: { eq: $id }) {
      acfMainTemplateFields {
        pageComponents {
          ... on WpPage_Acfmaintemplatefields_PageComponents_HeroSection {
            fieldGroupName
            title
            content
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ImageByContentBlock {
            fieldGroupName
            buttonRequired
            buttonSlug
            buttonText
            calloutTitle
            content
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentByImageBlock {
            fieldGroupName
            title
            content
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ThreeColumnsContent {
            fieldGroupName
            title
            step {
              buttonRequired
              buttonSlug
              buttonText
              content
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ColourBlock {
            buttonSlug
            buttonText
            content
            fieldGroupName
            title
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_JustATitle {
            fieldGroupName
            title
            addAQuote
            quote
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ImageByContentBlockStyleTwo {
            fieldGroupName
            buttonRequired
            buttonSlug
            buttonText
            calloutTitle
            content
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_LogoCallout {
            fieldGroupName
            callout
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_WysiwygByImagesSection {
            fieldGroupName
            wysiwyg
            gallery {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }

            mainImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ExternalLink {
            fieldGroupName
            buttonText
            content
            link
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ColourBlockVersionTwo {
            buttonSlug
            buttonText
            content
            fieldGroupName
            title
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_CircleImageByWysiwyg {
            fieldGroupName
            mainTitle
            wysiwygContent
            sectionId
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContactFormSection {
            fieldGroupName
            title
            content
            testimonialRequired
            testimoinal
            imageTop {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
            imageBottom {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }

            formFields {
              id
              inputType
              label
              placeholder
              required
              size
              options {
                fieldGroupName
                id
                label
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_WysiwygEditor {
            fieldGroupName
            wysiwygContent
          }
        }
      }
    }
  }
`

export default Page
